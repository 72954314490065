"use client";

import { useActionState } from "react";
import { useFormStatus } from "react-dom";

import SelectInput from "@/components/form/Select/Select";
import { TextArea } from "@/components/form/TextArea";
import { TextInput } from "@/components/form/TextInput";
import { useEffect, useState } from "react";

import { dataMap, reverseDataMap } from "@/config/interests";
import If from "@/components/If";
import { Button } from "@/components/actions/Button";
import { Stack } from "@/components/structure/Stack";
import { handleContactForm } from "@/actions";
import PhoneInput from "@/components/form/TextInput/PhoneInput";

import Script from "next/script";
import { sendGAEvent } from "@next/third-parties/google";

const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

const initialState = {
  message: "",
};

const validation = {
  validate: (formData, required) => {
    const missingFields = [];
    required.forEach((field) => {
      if (!formData[field]?.trim()) {
        missingFields.push(field);
      }
    });

    return missingFields;
  },
};

export default function Form({ page = "", location = "", theme = "default" }) {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [state, setState] = useState("idle");
  const [options, setOptions] = useState(reverseDataMap[page] || []);

  useEffect(() => {
    if (recaptchaLoaded) {
      window.grecaptcha.ready(() => {
        console.log("reCAPTCHA is ready");
      });
    }
  }, [recaptchaLoaded]);

  if (!RECAPTCHA_SITE_KEY) {
    console.error("reCAPTCHA site key is missing");
    return null;
  }

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
        onLoad={() => setRecaptchaLoaded(true)}
        strategy="lazyOnload"
      />
      <form
        onSubmit={async (ev) => {
          ev.preventDefault();
          setState("loading");
          if (!recaptchaLoaded) {
            console.error("reCAPTCHA not loaded");
            return;
          }

          const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
            action: "submit",
          });
          const formData = new FormData(ev.target);
          const data = Object.fromEntries(formData);
          data.token = token;
          const requiredFields = [
            "firstName",
            "lastName",
            "email",
            "phone",
            "interests",
            "token",
          ];
          const missingFields = validation.validate(data, requiredFields);
          if (missingFields.length) {
            setState("missingFields");
            return;
          }
          const resp = await fetch("/api/contact", {
            method: "POST",
            body: JSON.stringify(data),
          }).catch((err) => {
            console.error(err);
            setState("error");
            return {
              json: () => {},
            };
          });
          const json = await resp.json().catch((err) => {
            console.error(err);
            setState("error");
            return {};
          });

          if (!resp.ok) {
            setState("error");
            return;
          }

          if (json.error || json.missing) {
            setState("error");
            return;
          }

          if (json.success) {
            sendGAEvent("event", "contact_form_submission", {
              value: "success",
            });
            setState("success");
            return;
          }
          // /handleContactForm(ev);
        }}
      >
        <Stack gap="tight">
          <TextInput label="First Name" name="firstName" />
          <TextInput label="Last Name" name="lastName" />
          <PhoneInput label="Phone Number" name="phone" />
          <TextInput label="Email" name="email" />

          <If condition={theme !== "assisted-living-landing"}>
            <SelectInput
              placeholder="I’m interested in..."
              options={options?.map((location) => ({
                value: location,
                label: location,
              }))}
              name="interests"
              value=""
            />
          </If>
          <If condition={theme === "assisted-living-landing"}>
            <input type="hidden" name="interests" value="Assisted Living" />
          </If>
          <input type="hidden" name="location" value={location} />
          {/* {#if interests && dataMap[interests]}
						<Select
							name="location"
							label="Location"
							className="mb-8"
							bind:this={formData.location}
							bind:value={values.location}
							required
						>
							{#each dataMap[interests] as location}
								<option>{location}</option>
							{/each}
						</Select>
					{:else}
						<input type="hidden" name="location" value="N/A" />
					{/if} */}

          {/* <If condition={options?.length > 0}>
        <SelectInput
          placeholder="Location"
          options={options?.map((location) => ({
            value: location,
            label: location,
          }))}
          name="location"
          value=""
        />
      </If> */}
          <TextInput label="How did you hear about us?" name="howDidYouHear" />
          <TextArea
            label="Message"
            name="comments"
            placeholder="Enter your message here"
          />

          <label htmlFor="remark-reservation" className="remark">
            Remark
          </label>
          <input
            className="remark"
            name="remark"
            id="remark-reservation"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{(2, 4)}$"
            placeholder="name@domain.com"
          />

          <If condition={state === "missingFields"}>
            <p className="text-red-500">Please fill in all required fields</p>
          </If>

          <If condition={state === "error"}>
            <p className="text-red-500">
              There was an error submitting the form
            </p>
          </If>

          <If condition={state === "success"}>
            <p className="text-green-500 text-center">
              Form submitted successfully
            </p>
            <button
              onClick={() => setState("idle")}
              className="px-0 text-center text-orange underline"
            >
              Send Another Contact Form
            </button>
          </If>

          {/* <p aria-live="polite">{state?.message}</p> */}
          <If condition={state !== "success"}>
            <Button
              type="submit"
              className="w-max"
              disabled={state === "loading"}
            >
              <If condition={state === "loading"}>Submitting...</If>
              <If condition={state !== "loading"}>Submit</If>
            </Button>
          </If>

          <small className="text-[#929292] text-sm">
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              className="text-[#929292] underline text-sm"
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              className="text-[#929292] underline text-sm"
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{" "}
            apply.
          </small>
        </Stack>
      </form>
    </>
  );
}
