"use client";

import React, { useEffect, useRef } from "react";
import { spline } from "@georgedoescode/spline";
import { createNoise2D } from "simplex-noise";

const AnimatedBlob = () => {
  const pathRef = useRef(null);
  const requestRef = useRef(null);

  useEffect(() => {
    if (!pathRef.current) return;

    let hueNoiseOffset: number = 0;
    let noiseStep: number = 0.0005;

    const simplex = createNoise2D();

    // Helper functions
    function map(
      n: number,
      start1: number,
      end1: number,
      start2: number,
      end2: number
    ) {
      return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x: number, y: number) {
      return simplex(x, y);
    }

    function createPoints(): {
      x: number;
      y: number;
      originX: number;
      originY: number;
      noiseOffsetX: number;
      noiseOffsetY: number;
    }[] {
      const points: {
        x: number;
        y: number;
        originX: number;
        originY: number;
        noiseOffsetX: number;
        noiseOffsetY: number;
      }[] = [];
      const numPoints = 16;
      const angleStep = (Math.PI * 2) / numPoints;
      const rad = 90;

      for (let i = 1; i <= numPoints; i++) {
        const theta = i * angleStep;

        const x = 100 + Math.cos(theta) * rad;
        const y = 100 + Math.sin(theta) * rad;

        points.push({
          x: x,
          y: y,
          originX: x,
          originY: y,
          noiseOffsetX: Math.random() * 1000,
          noiseOffsetY: Math.random() * 1000,
        });
      }

      return points;
    }

    const points = createPoints();

    const animate = () => {
      if (pathRef.current) {
        pathRef.current.setAttribute("d", spline(points, 1, true));

        // Update each point position based on noise
        for (let i = 0; i < points.length; i++) {
          const point = points[i];

          // Get noise values for this point
          const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
          const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
          // Map noise to new positions
          const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
          const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

          // Update current coordinates
          point.x = x;
          point.y = y;

          // Progress through "time"
          point.noiseOffsetX += noiseStep;
          point.noiseOffsetY += noiseStep;
        }

        // Get a hue value from noise
        const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
        const hue = map(hueNoise, -1, 1, 0, 360);

        // Update colors with CSS variables
        document.documentElement.style.setProperty("--startColor", `#F3B465`);
        document.documentElement.style.setProperty("--stopColor", `#F3B465`);

        hueNoiseOffset += noiseStep / 6;
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    // Start animation
    requestRef.current = requestAnimationFrame(animate);

    // Handle hover events
    const handleMouseOver = () => {
      noiseStep = 0.01;
    };

    const handleMouseLeave = () => {
      noiseStep = 0.005;
    };

    // if (pathRef.current) {
    //   pathRef.current.addEventListener("mouseover", handleMouseOver);
    //   pathRef.current.addEventListener("mouseleave", handleMouseLeave);
    // }

    // Clean up
    return () => {
      cancelAnimationFrame(requestRef.current);
      //   if (pathRef.current) {
      //     pathRef.current.removeEventListener("mouseover", handleMouseOver);
      //     pathRef.current.removeEventListener("mouseleave", handleMouseLeave);
      //   }
    };
  }, []);

  return (
    <div className="blob-container">
      <svg viewBox="0 0 200 200">
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop
              id="gradientStop1"
              offset="0%"
              stopColor="var(--startColor, #f3b465)"
            />
            <stop
              id="gradientStop2"
              offset="100%"
              stopColor="var(--stopColor, #f3c065)"
            />
          </linearGradient>
        </defs>
        <path ref={pathRef} d="" fill="url(#gradient)" />
      </svg>
    </div>
  );
};

export default AnimatedBlob;
